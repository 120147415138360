import styled from 'styled-components';
import CustomButton from '../CustomButton';

import animatedShop from '../../assets/ressources/Animation-shop-up-man.gif';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const Container = styled.div`
    padding: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media screen and (max-width: 768px) {
        position: relative;
        padding: 0;
        padding-top: 20px;
        height: 60vh;
    }
`;

const ContainerContent = styled.div`
    padding: 25px;
    /* border: 1px solid #505050; */
    border-radius: 20px;
    height: 100%;
    max-width: 50%;
    box-shadow: 0 4px 8px rgba(223, 223, 223, 0.578);

    h3 {
        color: #3c3c3c;
    }

    p {
        color: #575757 !important;
    }

    @media screen and (max-width: 768px) {
        position: absolute;
        top: 40px;
        width: 90%;
        max-width: 100%;
        background: #f2f2f28d;
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
        height: 80%;

        p {
            font-size: 13px !important;
        }
    }
`;

const ImgMen = styled.img`
    width: 600px;
    padding: 15px;

    @media screen and (max-width: 768px) {
        /* position: absolute; */
        width: 100%;
        /* width: 200px; */
        right: 0;
        bottom: 35px;
        /* z-index: 1; */
        /* border-radius: 50%; */ 

        /* opacity: 0.7; */
    }
`;

function MenShopTemplate({ setCreateIsopen, showAuthModal }) {
    // const [isCreateOpen, setCreateIsopen] = useState(false);

    // const [showAuthModal, setshowAuthModal] = useState();

    // const [isAuthenticated, setIsAuthenticated] = useState(false);

    // function ShowCreateMenu() {
    //     setCreateIsopen(true);
    //     // console.log('opened');
    // }
    return (
        <Container>
            <ContainerContent className="content">
                <h3 data-aos="fade-up" data-aos-delay="300">
                    {' '}
                    Vos clients payent sur votre Shop, pas chez Elanga!
                </h3>
                <p data-aos="fade-up" data-aos-delay="500">
                    {' '}
                    Elanga ne va lister votre shop uniquement si vous le souhaitez, sinon vous êtes
                    responsables de vos ventes. <br />
                    En créant votre shop avec elanga, vous choisissez non seulement de digitaliser
                    votre business, mais d'accroitre encore plus et de devenir plus indépendant
                    comme vous ne l'avez jamais été.
                </p>

                <CustomButton setCreateIsopen={setCreateIsopen} showAuthModal={showAuthModal} />
            </ContainerContent>
            <ImgMen src={animatedShop} alt={animatedShop} />
        </Container>
    );
}

export default MenShopTemplate;
