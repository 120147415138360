import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

// Styled components
const ArticleContainer = styled.div`
  width: 250px;
  background-color: #dddddd6e;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 8px auto;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation: ${({ $isFadingOut }) => ($isFadingOut ? fadeOut : fadeIn)} 0.5s ease-in-out forwards;
  transition: transform 0.5s ease, box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

const ArticleImage = styled.img`
  width: 98%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ArticleTitle = styled.h6`
  font-size: 15px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-top: 5px;
`;

const RatingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
`;

const Star = styled.span`
  color: #ffd000 !important;
  opacity: ${props => (props.$active ? '1' : '0.3')}; 
  font-size: 18px !important;
`;

const RatingText = styled.span`
  margin-left: 4px;
  font-size: 8px !important;
  color: #353535 !important;
`;

const ArticlePrice = styled.p`
  font-size: 12px !important;
  font-weight: bold;
  color: #333 !important;
  align-self: flex-end;
`;

const Skeleton = styled.div`
  width: 250px;
  height: 200px;
  background: linear-gradient(90deg, #e0e0e071 25%, #f0f0f092 50%, #e0e0e08c 75%);
  background-size: 200% 100%;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  animation: ${keyframes`
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
  `} 1.5s infinite linear;

  margin: 8px auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

function RandomArticle() {
  const [articles, setArticles] = useState([]);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const navigate = useNavigate();

  // const apiUrl = 'http://localhost:3500';
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${apiUrl}/api/articles/all`);
        const data = await response.json();
        // console.log('Response data:', data);
        setArticles(data);
      } catch (err) {
        setError('Erreur lors de la récupération des articles');
        // console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (apiUrl) {
      fetchArticles();
    } else {
      // console.error('REACT_APP_BACKEND_URL is not defined');
    }
  }, []);

  const selectRandomArticle = useCallback(() => {
    if (articles.length > 0) {
      setIsFadingOut(true);
      setTimeout(() => {
        const randomIndex = Math.floor(Math.random() * articles.length);
        const selectedArticle = articles[randomIndex];
        const randomRating = Math.floor(Math.random() * 5) + 1; // Génère un rating entre 1 et 5
        setCurrentArticle({ ...selectedArticle, rating: randomRating });
        setIsFadingOut(false);
        // console.log('Article selected:', selectedArticle);
      }, 500);
    } else {
      setCurrentArticle(null);
    }
  }, [articles]);

  useEffect(() => {
    selectRandomArticle();
    const interval = setInterval(selectRandomArticle, 30000);

    return () => clearInterval(interval);
  }, [articles, selectRandomArticle]);

  const handleArticleClick = () => {
    if (currentArticle && currentArticle.shopId) {
      navigate(`/shop/${currentArticle.shopId}`);
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!currentArticle) {
    return null;
  }

  const imageUrl = currentArticle.image && currentArticle.image.length > 0
    ? currentArticle.image[0]
    : 'default-image-url.jpg';

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(<Star key={i} $active={i < rating}><ion-icon name="star"></ion-icon></Star>);
    }
    return stars;
  };

  return (
    <ArticleContainer $isFadingOut={isFadingOut} onClick={handleArticleClick}>
      <ArticleImage src={imageUrl} alt={currentArticle.title || 'Article'} />
      <ArticleTitle>{currentArticle.title}</ArticleTitle>
      <RatingContainer>
        {renderStars(currentArticle.rating)}
        <RatingText>{currentArticle.rating}/5</RatingText>
      </RatingContainer>
      <ArticlePrice>
        {currentArticle.price} {currentArticle.currency || '€'}
      </ArticlePrice>
    </ArticleContainer>
  );
}

export default RandomArticle;
