import styled from 'styled-components';

const Button = styled.button`
    padding: 15px 50px;
    border-radius: 25px;
    color: #000;
    background: #fff;
    border: 1px solid #000;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        /* border: 2px solid #fff; */
        background: transparent;
        /* color: #fff; */
        box-shadow: 0 0 55px 0 #232323;
    }
`;

function CustomButton({ setCreateIsopen }) {
    function ShowCreateMenu() {
        setCreateIsopen(true);
        // console.log('opened');
    }
    return (
        <div>
            <Button onClick={ShowCreateMenu}>Démarrer maintenant</Button>
        </div>
    );
}

export default CustomButton;
